import React from "react";

import Image from "./Image";

const ImagePanel = ({ servicesImagePanel }) => {
  return (
    <div className="relative 2xl:container">
      <Image
        image={servicesImagePanel.servicesImageImage}
        alt={servicesImagePanel.servicesImageImage.altText}
        className={`filter grayscale`}
      />
      <p className="text-sm text-white absolute bottom-8 left-24">
        {servicesImagePanel.servicesImageDescription}
      </p>
    </div>
  );
};

export default ImagePanel;
