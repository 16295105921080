import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import HeroServices from "../components/HeroServices";
import Testimonials from "../components/Testimonials";
import ImagePanel from "../components/ImagePanel";

export const pageQuery = graphql`
  {
    wp {
      page(id: "/our-services/", idType: URI) {
        id
        title
        servicesPage {
          servicesPageHero {
            servicesHeroImage {
              ...MediaItem
            }
            servicesHeroImageCaption
            servicesPanelColumns {
              servicesPanelColumn
            }
            servicesPanelLink {
              url
              title
            }
          }
          servicesImagePanel {
            servicesImageImage {
              ...MediaItem
            }
            servicesImageDescription
          }
          servicesTestimonialPanel {
            ...TestimonialContent
          }
        }
      }
    }
  }
`;

const services = ({ data }) => {
  const page = data.wp.page;
  const fields = page.servicesPage;
  return (
    <Layout title={page.title}>
      <HeroServices title={page.title} heroData={fields.servicesPageHero} />
      <ImagePanel servicesImagePanel={fields.servicesImagePanel} />
      <Testimonials testimonials={fields.servicesTestimonialPanel} />
    </Layout>
  );
};

export default services;
