import React from "react";

import Image from "./Image";
import LinkButton from "./LinkButton";

const HeroServices = ({ title, heroData }) => {
  return (
    <div className="relative mt-28 lg:mt-36 lg:flex justify-end 2xl:container">
      <Image
        image={heroData.servicesHeroImage}
        alt={heroData.servicesHeroImage.altText}
        className={`min-h-[350px] lg:min-h-[800px] z-2 screen-minus-nav block object-cover w-full lg:max-w-[75%] relative`}
      ></Image>
      <p className="text-sm text-white absolute transform translate-y-[-30px] lg:translate-y-[30px] lg:bottom-[17.5rem] right-12 md:right-24">
        {heroData.servicesHeroImageCaption}
      </p>
      <div className="services-clippath lg:absolute bottom-0 h-auto lg:flex lg:flex-col lg:justify-end left-0 z-1 w-full px-12 md:px-8 lg:px-12 py-16 lg:py-12 onefiveXL-px-24 text-white bg-gradient-to-b from-cerulean-500 to-cerulean-700">
        <h2 className="text-5xl leading-tight font-bold mb-4 uppercase text-white xl:text-index--4">
          {title}
        </h2>
        <div className="flex flex-wrap justify-between items-end">
          {heroData.servicesPanelColumns &&
            heroData.servicesPanelColumns.map((column, i) => {
              return (
                <div
                  key={i}
                  className="mb-8 relative lg:w-1/3 lg:max-w-[350px]"
                  dangerouslySetInnerHTML={{
                    __html: column.servicesPanelColumn,
                  }}
                ></div>
              );
            })}
        </div>
        <LinkButton
          link={heroData.servicesPanelLink.url}
          label={heroData.servicesPanelLink.title}
        />
      </div>
    </div>
  );
};

export default HeroServices;
